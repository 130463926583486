import { AddressStatuses } from "types/addresses";

import { accountThunks } from "store/account";

import { openAlert } from "components/dialog";

import {
  loadAddresses,
  createAddress,
  removeAddress,
  updateAddress,
} from "./thunks";
import { loadAddressesServer } from "./serverThunks";

import { AddressesBuilder } from "./duck/types";
import { addressesAdapter } from "./duck/operations";

const extraReducers = (builder: AddressesBuilder) => {
  //#region loadAddresses

  builder
    .addCase(loadAddresses.pending, state => {
      state.error = null;
      state.isLoading = true;
      state.isLoaded = false;
    })
    .addCase(loadAddresses.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isLoaded = true;

      addressesAdapter.setAll(state, action.payload);
    })
    .addCase(loadAddresses.rejected, (state, action) => {
      state.error = action.error.message;
      state.isLoading = false;
      state.isLoaded = true;
    });

  //#endregion

  //#region loadAddressesServer

  builder
    .addCase(loadAddressesServer.pending, state => {
      state.error = null;
      state.isLoading = true;
      state.isLoaded = false;
    })
    .addCase(loadAddressesServer.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isLoaded = true;

      addressesAdapter.setAll(state, action.payload);
    })
    .addCase(loadAddressesServer.rejected, (state, action) => {
      state.error = action.error.message;
      state.isLoading = false;
      state.isLoaded = true;
    });

  //#endregion

  //#region createAddress

  builder
    .addCase(createAddress.pending, state => {
      state.isCreating = true;
    })
    .addCase(createAddress.fulfilled, (state, action) => {
      state.isCreating = false;
      addressesAdapter.addOne(state, action.payload);
    })
    .addCase(createAddress.rejected, state => {
      state.isCreating = false;
    });

  //#endregion

  //#region updateAddress

  builder
    .addCase(updateAddress.pending, (state, action) => {
      state.statuses[action.meta.arg.id] = AddressStatuses.editing;
    })
    .addCase(updateAddress.fulfilled, (state, action) => {
      state.statuses[action.meta.arg.id] = null;
      addressesAdapter.upsertOne(state, action.payload);

      openAlert({
        type: "success",
        message: "Name of address was updated successfully",
      });
    })
    .addCase(updateAddress.rejected, (state, action) => {
      state.statuses[action.meta.arg.id] = null;

      openAlert({
        message: "An error occurred while updating the name of address",
      });
    });

  //#endregion

  //#region removeAddress

  builder
    .addCase(removeAddress.pending, (state, action) => {
      state.statuses[action.meta.arg] = AddressStatuses.removing;
    })
    .addCase(removeAddress.fulfilled, (state, action) => {
      state.statuses[action.meta.arg] = null;
      addressesAdapter.removeOne(state, action.meta.arg);

      openAlert({
        type: "success",
        message: "Address was deleted successfully",
      });
    })
    .addCase(removeAddress.rejected, (state, action) => {
      state.statuses[action.meta.arg] = null;

      openAlert({
        message: "An error occurred while deleting the address",
      });
    });

  //#endregion

  //#region account.signOut

  builder.addCase(accountThunks.signOut.fulfilled, state => {
    addressesAdapter.removeAll(state);
  });

  //#endregion
};

export default extraReducers;
