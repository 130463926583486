import servicesAPI from "api/services";

import { getPaymentCategories } from "operations/payment";

import { getDefault } from "selectors/common";

import { regionsSelectors, regionsThunks } from "store/regions";

import { createAppAsyncThunk } from "../duck/types";

export const loadCustomPaymentMethod = createAppAsyncThunk<
  { currencies: CurrencyWithDefault[]; paymentMethod: PaymentMethod },
  Region["letterId"] | void
>(
  "payment/loadCustomPaymentMethod",
  async (regionLetterId, { dispatch, getState }) => {
    await dispatch(regionsThunks.loadRegions());
    const regions = regionsSelectors.selectRegions(getState());
    const allRegionsMap = regionsSelectors.selectAllRegionsMap(getState());

    let region = regionLetterId
      ? allRegionsMap[regionLetterId]
      : getDefault(regions);
    if (region.childExists) {
      region = getDefault(region.states) || Object.values(region.states)[0];
    }

    const [rawPaymentTypes, currencies] = await Promise.all([
      servicesAPI.fetchPaymentTypesForRegion(region.id),
      servicesAPI.fetchCurrenciesForRegion(region.id),
    ]);

    const paymentTypes = getPaymentCategories(rawPaymentTypes);

    return {
      currencies,
      paymentMethod: {
        id: -1,
        createdAt: new Date().toJSON(),
        region,
        categories: paymentTypes,
        currency: getDefault(currencies),
      },
    };
  },
  {
    condition(arg, api) {
      if (!arg && api.getState().payment.isDefault) {
        return false;
      }
    },
  },
);
