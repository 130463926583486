import { FunctionComponent, SVGProps } from "react";

import Error40Icon from "icons/error40.svg?react";
import Warning40Icon from "icons/warning40.svg?react";
import Success40Icon from "icons/success40.svg?react";

import { TimeMilliseconds } from "constants/time";

export const ALERT_ICONS_MAP: Record<
  Alert["type"],
  FunctionComponent<SVGProps<SVGSVGElement>>
> = {
  error: Error40Icon,
  warning: Warning40Icon,
  success: Success40Icon,
};

export const CLOSE_TIMEOUT_MAP: Record<Alert["type"], number> = {
  error: TimeMilliseconds.second * 7,
  warning: TimeMilliseconds.second * 5.5,
  success: TimeMilliseconds.second * 4,
};

export const PROGRESS_COLORS: Record<Alert["type"], string> = {
  error: "#f74e31",
  success: "#1eaf13",
  warning: "#ec9d1f",
};

export const ICON_SHADOW_COLORS: Record<Alert["type"], string> = {
  error: "#ff0d0d66",
  success: "#00ae0066",
  warning: "#ff9e0d66",
};
