import { PAYMENT_OPTIONS } from "constants/payment";

export const getPaymentCategories = (paymentTypes: PaymentType[]) => {
  const paymentCategories = {} as Record<PaymentTypeCategory, PaymentCategory>;

  let newOptionsIndex = Object.values(PAYMENT_OPTIONS).length;

  paymentTypes.forEach(paymentType => {
    const categoryOption = paymentCategories[paymentType.category];
    if (categoryOption) {
      categoryOption.ids.push(paymentType.id);
      categoryOption.options.push(paymentType);

      return;
    }

    const index = PAYMENT_OPTIONS[paymentType.category];
    const isIndex = typeof index === "number";

    paymentCategories[paymentType.category] = {
      index: isIndex ? index : newOptionsIndex,
      name: paymentType.category,
      icon: paymentType.categoryIcon,
      description: paymentType.categoryDescription,
      ids: [paymentType.id],
      options: [paymentType],
    };

    if (!isIndex) {
      newOptionsIndex += 1;
    }
  });

  return Object.values(paymentCategories).sort((a, b) => a.index - b.index);
};

export const getPaymentTypesIds = (categories: PaymentCategory[]) =>
  categories.reduce((acc, paymentType) => acc.concat(paymentType.ids), []);

export const processPaymentMethod = ({
  paymentMethods,
  ...paymentMethodInfo
}: PaymentMethodDTO): PaymentMethod => ({
  ...paymentMethodInfo,
  categories: getPaymentCategories(paymentMethods),
});
